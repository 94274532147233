@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";


.option-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:  flex-start;   
} 

.option-label {
  width: 200px;
  margin: 0 20px 0 10px;
}

.color-label {
  height: 24px;
  width: 32px;
  border: 1px solid #aaa;
  margin: 0 24px;    
}

.options-panel {
  display: flex;
  border-bottom: 1px solid #ddd; 
  max-height: 640px;
}

.menu {
  padding: 20px 0 100px;
  border-right: 1px solid #ddd;
}

.menu-item {
  cursor: pointer;
  margin: 8px 0;
  padding: 8px 32px;
  min-width: 180px;
  border-right: 3px solid transparent;
  transition: color 200ms linear, border-color 200ms linear;
  user-select: none;
}

.sel-menu-item {
  color: #E40000;
  border-right: 3px solid #E40000;
}

.options-editor {
  display: grid;
}

.options-group {
  grid-area: 1 / 1;
  z-index: 1;
  background: white;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.options-group.visible {
  visibility: visible;
  z-index: 2;
}

.array-button {
  margin: 0;
}

.array-button:disabled .md-icon {
  opacity: 0.5;
}

.color-preview {
  min-width: 30px; 
  height: 30px;
  margin: 0 16px;
  cursor: pointer;
}

.add-button .md-button-content {
  display: flex;
  align-items: center;
}

.color-dialog {
  min-width: 120px !important;
}

.reset-button {
  opacity: 0.3;
}

.reset-button:hover {
  opacity: 1;
}

