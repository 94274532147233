@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";

.list-badge {
  background: #cccccc;
  border-radius: 0.6em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.2em;
  margin-right: 0.3em;
  text-align: center;
  width: 1.2em; 
  font-size: 10px;
}

.letter {
  vertical-align: text-bottom;
}
