@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";

.filter-container {
  flex: 1 0 0;
  min-width: 0;
  display: flex;
  flex-direction: column;
}

.filter-list-item-label {
  color: rgba(0, 0, 0, 0.87);
}

.filter-list-item-values {
  color: $primary;
}

.heading {
  color: $primary;
  flex: 0 0 auto;
  padding: 16px 16px 12px 16px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid rgb(221, 221, 221);
  white-space: nowrap;
  text-transform: uppercase;
}

.content {
  flex: 1 0 0;
}

.footer {
  flex: 0 0 auto;
  border-top: 1px solid rgb(221, 221, 221);
  text-align: center;
}  

.dialog-search-sort {
  display: flex;
  align-items: center;
  margin: 0 12px;

  > div:first-child {
    flex: 1 0 auto;
    .md-field {
      margin: 0 12px 16px 16px; 
      min-height: 32px;
      width: auto;
    }
  }

  > .md-icon {
    margin: 0 16px 0 0;
  }

  > button {
    margin: 0;
  }
}

:deep {
  .md-list {
    padding: 0;
  }

  .md-list-item {
    border-top: 1px solid $border-grey;
    
    .md-icon {
      color: rgba(0, 0, 0, 0.7);
      opacity: .2;
      transition: opacity 300ms; 
    }
  }

  .md-list-item-content {
    min-height: auto;
    padding: 0px 16px 0 4px;
  }

  .md-list-item-text {
    font-size: 14px;
  }

  .md-list-item:first-child {
    border-top: none;
  }

  .md-list-item:hover {
    .md-icon {
      opacity: 1;
    }
  }
}

.treeselect-wrapper {
  min-height: 320px;
}

.help {
  ul {
    margin: 0;
    li {
      margin: 0;
      padding-left: 8px;
    }
  }
  .examples {
    span {
      display: inline-block;
      margin: 0 8px 4px 0;
      background: #e2e2e2;
      padding: 4px 8px;
    }
  }
}
