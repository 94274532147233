@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";

.views-container {
  flex: 1 0 0;
  min-width: 0;
  display: flex;
  flex-direction: column;

  .view-active {
    background: #eee;
  }
}

.source-select {
  padding: 16px 16px 0;
}

.heading {
  color: $primary;
  flex: 0 0 auto;
  padding: 16px 16px 12px 16px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid rgb(221, 221, 221);
  white-space: nowrap;
  text-transform: uppercase;
}

.list {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;

  .list-item {
    font-size: 14px;
    padding: 0 5px 0 15px;
    border-top: 1px solid $border-grey;
  }

  .list-item-title {
    font-weight: 400 !important;
    font-size: 14px !important;
  }

  .list-badge {
    margin: 0 0 0 0;
  }

  .list-item:first-child {
    border-top: none;
  }

  .view-label {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 0 0 5px;
  }
}

.list-item-action {
  margin: 0 !important;
}
