@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";

.chips {
  display: flex;
  position: relative;
  overflow: auto;
  padding: 0;
  min-height: 52px;
  align-items: center;
  z-index: 1000;
  border-top: 1px solid #ddd;
  background: #f5f5f5;

  .chips-group {
    float: left;
    background: #fff;
    margin: 3px 5px 3px 0;
    padding: 5px 5px 5px 10px;
    border-radius: 16px;
  }

  .chips-group-name {
    font-size: 13px;
    font-weight: 500;
    white-space: nowrap;
  }

  .chips-group-chips {
    display: flex;
    align-items: center;
  }

  :deep {
    .md-chip {
      height: 26px;
      line-height: 26px;
      font-size: 13px;
      margin: 0 0 0 10px;
      background: #999;
      overflow: hidden; 
      text-overflow: ellipsis;
      max-width: 150px;
    }

    .md-chip:first-child {
      margin: 0;
    }
  }
}
