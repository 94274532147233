@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";

.tq-save-analysis-settings {
    flex: 1 0 0;
    min-width: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: #fff;

    .heading {
      flex: 0 0 0;
      padding: 16px 16px 12px 16px;
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid rgb(221, 221, 221);
      white-space: nowrap;
      color: $primary;
      text-transform: uppercase;
    }
  }
