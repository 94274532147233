@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";

.i-frame {
  border: 1px solid #eee;
}

.full-flex {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}
