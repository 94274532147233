@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";

// TEMP: Layout fixes
.spg-question__content {
  position: relative;
}

.sv_q_dropdown_clean-button {
  display: none;
}
