@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";

.tq-apply, .tq-apply-overlay, .tq-apply-content-wrapper {
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   pointer-events: none;
   z-index: 100;
 }

 .tq-apply {
   .tq-apply-overlay {
     background: #fff;
     opacity: 0.7;
   }

   .tq-apply-content-wrapper {
     display: flex;
     align-items: center;
     justify-content: center;
   }

   .tq-apply-content {
     pointer-events: auto;
     background: #fff;
     box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);
     padding: 32px 48px;
   }

   .tq-apply-text {
     font-size: 18px;
     font-weight: 500;
     margin: 0 0 24px;
     text-align: center;
   }

   .tq-apply-button {
     text-align: center;
   }


   /* ToDo: */
   .ag-body-horizontal-scroll-viewport {
     min-width: 200px;
   }

 }
