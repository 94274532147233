@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";

#app {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .md-app {
        flex: 1 0 0;
        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .app-logo {
        height: 48px;
        width: 129px;
    }
    
    .app-logo-wrapper {
        height: 48px;
        width: 129px;
        margin-right: 16px;
    }
}

#content {
    padding: 0;
    display: flex;
    flex-direction: column;
    border-left: none;
    border-right: none;
    align-items: center;
    background: #fafafa;
}

footer {
    flex: 0 0 40px;
    background: #fff;
    padding: 0 16px;
    z-index: 3;
    font-size: 13px;
    color: #333;
    border-top: 1px solid #ddd;
}

.badge {
    width: 19px;
    height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 8px;
    right: 8px;
    background: $red;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -.05em;
}

a:hover {
    text-decoration: none;
}
