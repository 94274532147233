@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";



#tq-pivot-table {


  .table-footer {
    border-top: 1px solid #eee;
    padding: 4px 8px 2px;
    text-align: left;
  }

  :deep .ag-theme-material {

    --ag-range-selection-border-color: #cd2927;

    .value-cell {
      width: 100%;
      text-align: right;
    }

    .custom-tooltip {
      position: absolute;
      display: flex;
      flex-direction: column;
      padding: 4px 8px;
      overflow: hidden;
      pointer-events: none;
      transition: opacity 300ms;
      background: white;
    }

    .custom-tooltip.ag-tooltip-hiding {
      opacity: 0;
    }

    .custom-tooltip p {
      margin: 5px;
      white-space: nowrap;
    }

    .ag-status-bar {
      border: none;
    }

    .ag-header {
      box-sizing: content-box;
      border-bottom: none;
    }

    .ag-header-container, .ag-pinned-left-header {
      border-bottom: solid 1px;
      border-bottom-color: var(--ag-border-color, #e2e2e2);
    }

    .auto-expand,
    .ag-header-cell-label,
    .ag-header-group-cell-label {
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 4px;
    }

    .ag-header-group-cell-label {
      align-items: center;
    }

    .ag-header-group-text {
      height: auto;
    }

    .ag-cell,
    .ag-header-cell,
    .ag-header-group-cell {
      display: flex;
      align-items: stretch;
      line-height: 20px;
      padding: 0;
      overflow: hidden;
    }

    .ag-cell:not(.ag-cell-range-selected.ag-cell-range-right,.ag-cell-range-single-cell),
    .ag-header-cell,
    .ag-header-group-cell {
      border-right: 1px solid #ddd;
    }

    .ag-header {
      color: inherit;
    }

    .ag-header-cell,
    .ag-header-group-cell:not(.ag-header-group-cell-no-group) {
      background-color: #f8f8f8;
    }

    .ag-header-group-cell-no-group:not(:last-of-type) {
      border-right: none;
    }

    .ag-cell-value>span,
    .ag-cell-label-container {
      display: flex;
      align-items: center;
      flex: 1 0 0;
      min-width: 0;
    }

    .ag-header-cell .ag-cell-label-container {
      flex-flow: row-reverse;
      justify-content: flex-end;
      min-width: 100%;
    }

    /* Inherit background, so expanded cells can use it */
    .ag-header-container,
    .ag-header-viewport,
    .ag-pinned-left-header,
    .ag-header-row {
      background-color: inherit;
    }

    /* By default the group checkbox occupies some space due to it's left margin. */
    .ag-group-checkbox.ag-invisible {
      margin: 0;
    }

    /* Make sure expansion arrow is vertically centered and layout is balanced */
    .ag-group-expanded,
    .ag-group-contracted {
      line-height: 1;
      padding: 4px;
    }

    .ag-group-value {
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 4px;
    }

    .ag-header-cell-label {
      align-items: center;
      padding-left: 4px;
    }

    /* Allow line breaks in headers */
    .ag-header-cell-label .ag-header-cell-text {
      white-space: pre;
      width: 100%; /* Without this there are rendering problems on Firefox */
    }

    .ag-sort-ascending-icon,
    .ag-sort-descending-icon {
      margin-left: 4px;
    }

    .ag-header-icon,
    .ag-header-icon .ag-icon {
      height: 100%;
    }

    .ag-cell-label-container:not(:hover) .ag-header-cell-menu-button {
      /* Make header menu icon occupy no space if not displayed */
      width: 0;
    }

    .ag-cell-range-selected-1:not(.ag-cell-focus) {
      background-color: #FBDBDB;
    }

    .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-bottom {
      border-bottom-color: #cd2927;
    }

  }

}
