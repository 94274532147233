@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";


.center-vertical {
  display: flex;
  align-items: center;
}

.detail {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: hidden;
  border-left: 1px solid rgba(34, 36, 38, 0.15);
  width: 100%;
  min-width: 300px;

  .detail-toolbar {
    flex-flow: row;
    flex: 0 0 52px;
    min-height: 52px;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    background: #fff;

    .text-row {
      color: $primary;
      font-size: 16px;
      font-weight: 500;
    }

    >span {
      white-space: nowrap;
    }

    .label-span {
      min-width: 0;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .detail-container {
    overflow: auto;
  }

  .detail-heading {
    font-size: 18px;
    padding: 16px 24px;
  }

  .detail-item {
    flex: 1 0 0;
    padding: 16px 24px;
  }

  .detail-inner {
    flex: 1 0 0;
    min-height: 0;
    display: flex;
    flex-direction: column;
  }
}


.audioplayer-detail {
  min-height: 0;
}

