@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";

.phone-call-dialer {
  min-width: 370px;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
}

.call-box {
  flex: 0 0 auto;
  min-height: 0;
  padding: 15px;
  overflow: auto;
  border-bottom: 1px solid rgb(233, 233, 233);

  .button {
    margin: 0 0 0 8px;
  }
}

.source-phone-number {
  padding: 0 0;

  label {
    color: #fff !important;
    top: 8px !important;
  }

  input {
    min-width: 250px !important;
    color: #fff !important;
    -webkit-text-fill-color: #fff !important;
  }
  
  .md-field {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    color: #fff;
    min-height: 32px;
  }

  .md-field.md-theme-toolbar:after {
    background-color: #fff;
  } 
}

.dialpad {
  padding: 0 0 20px;
}

.dialpad-row {
  display: flex;
  justify-content: center;
}

.dialpad-digit {
  margin: 2px;
  padding: 10px 30px;
  float: 1 1 auto;
  font-size: 2rem;
  cursor: pointer;
  text-align: center;
}

.sub {
  font-size: 0.8rem;
  color: grey;
}

.info-box {
  padding: 15px;

  .label {
    color: #aaa;
    font-weight: bold;
    padding: 2px 0 4px;  
  }
  
  .status {
    padding: 2px 0 4px;
  }  
}

.tool-box {
  flex: 1 1 0;
  overflow: auto;
}

.phone-numbers {
  padding: 0 0 10px;
}

// make tab items stretch to full height
:deep {
  .data-entry-dialog  {
    overflow-y: visible;
  }

  .sv_body {
    padding: 0 !important;
    border: none !important;
  } 

  .v_window, .v-window__container, .v-window-item, .v-item-group {
    flex: 1 1 0;
    min-height: 0;
    display: flex;
  }

  .tabs-sidebar {
    display: flex;
    flex: 0 0 auto;
    box-shadow: none;
    border-left: 1px solid rgb(233, 233, 233);
  }
}

