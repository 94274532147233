@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";

.heading {
  flex: 0 0 auto;
  padding: 16px 16px 12px 16px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid rgb(221, 221, 221);
  white-space: nowrap;
  color: $primary;
  text-transform: uppercase;
}

.content {
  flex: 1 0 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 24px 12px;
}


.settings-container {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;  
}

.apply-button {
  width: 100%;    
  flex: 0 0 auto;
  box-sizing: content-box;
  margin: 0;
}
