@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";

#tq-analysis {
  $animation-speed: 500ms;


  @media screen and (max-width: 1200px) {
    .toolbar-buttons {
      display: none;
    }
  }

  @media screen and (min-width: 1201px) {
    .toolbar-menu {
       display: none;
    }
  }
 

  .content-left {
    display: flex;
    position: relative;
    flex-direction: column;
    min-width: 0;
    overflow: hidden;
    height: 100%;
    transition: flex $animation-speed;
    background: #fff;
    z-index: 102;
    

    .content-left-tool {
      transition: background 500ms;

      .md-button {
        min-height: 60px;
        min-width: 50px;
        margin: 0;
        border-radius: 0;
      }
      
      .md-icon {
        font-weight: 400;
      }
    }

    /*.content-left-tool-active {
      background: #fff;
      
      .md-button {
        background: #fff !important;
      }

      .md-button:before {
        background: #fff !important;
      }        
    }*/
  }

  .content-left-wrapped {
    flex: 1 0 0;
    display: flex;
    overflow: auto;
  }

  .content-left-tools {
    position: relative;
    flex: 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: top;
    background: #f5f5f5;
    border-right: 1px solid #ddd;
  }

  .content-left-tools-bar {
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 60px;
    background: #cd041e;
    transition: top 300ms;
  }

  .drawer-handle-container {
    position: relative;
    flex: 0 0 0;
    color: #4e5152;
    border-left: 0 solid #777;
    transition: border-width 400ms;
    background: #777;

    .drawer-handle {
      z-index: 101;
      background: #777;
      top: calc(50%);
    }  
  }

  .drawer-handle {
    opacity: 1;
    $drawer-diameter: 40px;
    $drawer-radius: $drawer-diameter / 2;
    position: absolute;
    z-index: 1;
    left: -$drawer-radius;
    height: $drawer-diameter;
    width: $drawer-diameter;
    border-radius: $drawer-radius;
    margin: -$drawer-radius 0 0 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 1s;
    transition: top 300ms;
    
    .drawer-handle-inner {
      margin: 0 0 0 $drawer-radius;
      width: $drawer-radius;
      position: relative;
      left: -3px;

      i {
        color: #fff;
        transition: transform $animation-speed;
      }
    }
  }

  .menu-button {
    font-size: 13px;
  }
  
  .menu-button .md-icon {
    margin: 0 4px;
  }

  .menu-button .md-button-content {
    display: flex;
    align-items: center;
  }

}

#tq-analysis.show-drawer {
  .filters {
    flex: 0 0 300px;
  }

  .drawer-handle-container {
    .drawer-handle {
      opacity: 1;
      i {
        transform: rotate(180deg);
      }
    }
  }
}

#tq-analysis:not(.show-drawer) {
  .drawer-handle-container:hover {
    border-left-width: 5px;
  }      
}

#tq-analysis.sidebar-views {
  .content-left-icons-views {
    color: $primary;
  }

  .content-left-tools-bar {
    top: 0;
  }
}

#tq-analysis.sidebar-filters {
  .content-left-icons-filters {
    color: $primary;
  }

  .content-left-tools-bar {
    top: 60px;
  }
}

#tq-analysis.sidebar-bookmarks {
  .content-left-icons-bookmarks {
    color: $primary;
  }

  .content-left-tools-bar {
    top: 180px;
  }
}

#tq-analysis.sidebar-settings {
  .content-left-icons-settings {
    color: $primary
  }

  .content-left-tools-bar {
    top: 120px;
  }
}

