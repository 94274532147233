@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";

.ace-container {
  overflow: auto;
  display: flex;
  flex: 1 0 0;
  background: #f0f0f0;
  border-top: 1px solid rgb(233, 233, 233);
}

.full-flex {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.iframe {
  border: 1px solid #eee;
}

.render-error {
  padding: 20px;
}

.show-code {
  position: absolute;
  right: 10px;
  top: 10px;
}

.vue-composer-preview {
  position: relative;
}

.tab-item {
  height: 300px;
}

$tabsSidebarWidth: 370px;

// make tab items stretch to full height
:deep {
  .data-entry-dialog  {
    overflow-y: visible;
  }

  .sv_body {
    padding: 0 !important;
    border: none !important;
  } 

  .v_window, .v-window__container, .v-window-item, .v-item-group {
    flex: 1 1 0;
    min-height: 0;
    display: flex;
  }
}

.tabs-sidebar {
  display: flex;
  flex: 0 0 auto;
  box-shadow: none;
  border-left: 1px solid rgb(233, 233, 233);
}
