@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";

#tq-phone-call-dialog {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 100000;
  background: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
}
