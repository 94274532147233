$red: #cd041e;
$red-light: #dd2840;
$grey: #4b4b4d;
$grey-dark: #363839;
$grey-light: #f2f2f2;
$blue: #137DBF;
$blue-light: rgba(146, 215, 255, 0.171);

$primary: #cd041e;
$accent: $blue;
$background: #fff;

$border-grey: #d9dcde;

body {
  --primary: #cd041e;
  --secondary: #137DBF;
}