@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";

.analysis-settings-bookmark {
  display: flex;
  align-items: center;
  padding: 2px 10px 2px 40px;
  cursor: pointer;
  min-height: 40px;

  .text {
    flex: 1 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .icon {
    padding: 0 0 0 3px;
  }
}

.analysis-settings-bookmark:hover {
  background: rgb(246, 246, 246);
}

.selected, .analysis-settings-bookmark.selected:hover {
  background: #eee;
}


