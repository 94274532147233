@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";

$tabsSidebarWidth: 370px;

// make tab items stretch to full height
:deep {
  .data-entry-dialog  {
    overflow-y: visible;
  }

  .sv_body {
    padding: 0 !important;
    border: none !important;
  } 

  .v_window, .v-window__container, .v-window-item, .v-item-group {
    flex: 1 1 0;
    min-height: 0;
    display: flex;
  }
}


.tabs-sidebar {
  display: flex;
  flex: 0 0 $tabsSidebarWidth;
  box-shadow: none;
  border-left: 1px solid rgb(233, 233, 233);
}
