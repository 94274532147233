@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";

.analysis-settings-tree {
  border-top: 1px solid $border-grey;

  .root-folder {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px 5px 4px;
  }

  .root-folder-text {
    flex: 1 0 0;
    padding: 0 0 0 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .root-folder-icon {
    transition: transform 200ms;
  }

  .root-folders.closed {
    .bookmarks {
      height: 0;
    }

    .root-folder-icon {
      transform: rotate(-90deg);
    }
  } 

  .bookmarks {
    overflow: hidden;
  }

  .tree-content {
    overflow: hidden;
  }

  .tree-heading {
    display: flex;
    align-items: center;
    padding: 5px 10px 0 5px;
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-bottom: 1px solid $border-grey;

    .tree-heading-toggle {
      cursor: pointer;
    }

    .tree-heading-icon {
      transition: transform 200ms;
    }

    .tree-heading-text {
      font-weight: 500;
      padding: 0 0 0 10px;
      vertical-align: middle;
      flex: 1 1 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.analysis-settings-tree.closed {
  .tree-content {
    height: 0;
  }

  .tree-heading-icon {
    transform: rotate(-90deg);
  }
}

.analysis-settings-tree:first-child {
  border-top: none;
}
