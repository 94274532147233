@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";

.buttons {
  margin-top: 5px;
  text-align: right;
}

.header {
  color: #aaa;
  padding: 0 0 3px;
}

.textarea {
  height: 200px;
}
