@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";

.ace-editor-container {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.ace-editor {
  position: relative;
  flex: 1 0 0;
  border-bottom: 1px solid rgb(233, 233, 233);
}

.beautify-icon {
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
}
