@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";


 .setting-container {
   padding: 0;
   border-left: 4px solid transparent;
 }

 .setting-container.error {
   border-left-color: red;
   background: inherit !important;
 }

 .item-list-title {
   color: $primary;
   padding-left: 10px;
   height: 40px;
   line-height: 40px;
   min-width: 0;
   overflow: hidden;
   text-overflow: ellipsis;
 }

 .search-field {
   transition: width 200ms;
   width: 100%;
   min-height: 32px;
   margin: 0 16px 0 8px;
 }

 .item-list {
   flex: 1 1 0;
   overflow-x: hidden;
   overflow-y: auto;
   height: 100%;
   border: 1px solid #ccc;
 }

 .item-list2 {
   max-height: 116px;
   margin: 4px 8px 24px;
   border-color: #bbb !important;
 }

 .list-group-item {
   font-size: 14px;
   border-top: 1px solid #eee;
   margin-top: -1px;
   padding: 4px 12px 4px 8px;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   text-overflow: ellipsis;
   cursor: default;
 }

 .dnd-list-item {
   padding-left: 0;
   cursor: grab;    
 }

 .dnd-list-item>div:first-of-type {
   padding-right: 8px;
 }

 .dnd-list-item:hover {
   background-color: #f2f2f2;
 }

.list-group-item .drag-indicator {
  color:#ccc;
}

 .md-list-item-container {
   color: inherit;
 }

 .list-group-item .md-list-item-content {
   color: green;
   min-height: 40px;
 }

 .error-icon {
   color: red !important;
 }

 .list-edit-button {
   margin-left: auto;
   opacity: 0.2;
   transition: opacity 0.4s;
   transform: scale(0.9);
 }

 .md-layout-item:hover .list-edit-button,
 .parameter-list-item .list-edit-button:hover {
   opacity: 1;
 }

 .text-field {
   min-height: 32px;
   margin: 4px 8px 24px;
   padding-top: 0;
   width: auto;
 }

 .text-field label {
     top: 7px;
 }

 .md-focused.text-field label {
     top: 7px;
     opacity: 0;
     color: inherit;
     font-weight: inherit;
     text-transform: inherit;
 }

 .ratio-list-item {
   font-size: 14px;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: stretch;
   text-overflow: ellipsis;
 }

 .ratio-list-item:not(:first-of-type) {
   border-top: 1px solid #ddd;
 }

 .parameter-list-item {
   padding: 4px 12px 4px 8px;
   display: flex;
   flex-direction: column;
   align-items: stretch;
 }

 .parameter-list-item:last-of-type {
   padding-bottom: 12px;
 }

 .menu-select {
     width: 100%;
     margin-top: 5px;
     cursor: default;
 }

 .menu-select>div:first-child {
   display: flex;
   align-items: center;
   margin: 0;
   padding-left: 10px;
   border: 1px solid #bbb;

 }

 .menu-select .md-button {
   margin-left: auto;
   min-width: 28px;
   width: 28px;
   height: 28px;
 }

 .menu-select-field::after {
   display: none;
 }

 .no-field-separator::after {
   background: none;
 }

