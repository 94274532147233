@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";


.audioplayer-wrapper {
  flex: 1 1 0;
  display: flex;
  background-color: #fff;
}

.loading {
  display: flex;
  flex: 1 1 0;
  justify-content: center;
  align-items: center;
}

.audioplayer {
  max-width: 370px;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
}

.playlist-container {
  flex: 1 1 0;
  overflow: auto;
}

.playlist {
  padding: 0;
}

.playlist .md-list-item-container {
  color: #222;
}

div.amplitude-wave-form{
    margin-top: -14px;
}
      
 div.amplitude-wave-form svg{
      stroke: #E40000;
      height: 50px;
      width: 100%;
      stroke-width: .5px;
}

 div.amplitude-wave-form svg g{
   stroke: #E40000;
   height: 50px;
   width: 100%;
}
 div.amplitude-wave-form svg g path{
   stroke: #E40000;
   height: 50px;
   width: 100%;
}

div.amplitude-wave-form{
    margin-top: -16px;
}
      
.volume-container {
  display:flex;
  align-items: center;
}

.volume-container, .playback-speed-container {
  margin: 4px 16px;
}

.volume-container .slider, .playback-speed-container .slider {
  flex: 1 1 0;
}

.playback-speed-container {
  display:flex;
  align-items: center;
}

.volume-icon, .playback-speed-icon {
  margin-right: 8px;
}

.volume-value, .playback-speed-value {
  width: 40px;
  margin-left: 8px;
}


.playlist li.active-soundfile-list-item {
  background: #f4f4f4;
}

.playlist li .md-button i {
  opacity: 0.4;
  transition: opacity 200ms;
}

.playlist li .md-button i:hover {
  opacity: 0.5;
}

.playlist li.amplitude-active-song-container .md-button i {
  opacity: 1;
}


/*
  1. Base
*/

/*
  2. Components
*/
div.amplitude-player {
  display: flex;
  flex: 0 0 0;
  flex-direction: column;
  padding: 20px;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(233, 233, 233);
  display: flex;
  max-width: 900px; 
  background-color: #fafafa;
  }

/* Small only */
@media screen and (max-width: 39.9375em) {
  div.amplitude-player {
    flex-direction: column; } }
/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  div.amplitude-player {
    max-height: 715px; 
  } }
/* Large and up */
@media screen and (min-width: 64em) {
  div.amplitude-player {
    max-height: 715px;
  } }
div#amplitude-left {
  padding: 0;
  border-right: 1px solid #CFD8DC;
  width: 50%;
  display: flex;
  flex-direction: column; }
  div#amplitude-left img.album-art {
    width: 100%; }
  div#amplitude-left div#player-left-bottom {
    flex: 1;
    background-color: #F1F1F1;
    padding: 20px 10px; }
    





div.progress-container {
  width: 70%;
  float: left;
  position: relative;
  height: 20px;
  cursor: pointer;
  /*
    IE 11
  */ }
  div.progress-container:hover input[type=range].amplitude-song-slider::-webkit-slider-thumb {
    display: block; }
  div.progress-container:hover input[type=range].amplitude-song-slider::-moz-range-thumb {
    visibility: visible; }
  div.progress-container progress.song-played-progress {
    position: absolute;
    left: 0;
    top: 8px;
    right: 0;
    width: 100%;
    z-index: 60;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 4px;
    border-radius: 5px;
    background: transparent;
    border: none;
    /* Needed for Firefox */ }
  @media all and (-ms-high-contrast: none) {
    div.progress-container *::-ms-backdrop, div.progress-container progress.song-played-progress {
      color: #E40000;
      border: none;
      background-color: #CFD8DC; } }
  @supports (-ms-ime-align: auto) {
    div.progress-container progress.song-played-progress {
      color: #E40000;
      border: none; } }
  div.progress-container progress.song-played-progress[value]::-webkit-progress-bar {
    background: none;
    border-radius: 5px; }
  div.progress-container progress.song-played-progress[value]::-webkit-progress-value {
    background-color: #E40000;
    border-radius: 5px; }
  div.progress-container progress.song-played-progress::-moz-progress-bar {
    background: none;
    border-radius: 5px;
    background-color: #E40000;
    height: 5px;
    margin-top: -2px; }
  div.progress-container progress.song-buffered-progress {
    position: absolute;
    left: 0;
    top: 8px;
    right: 0;
    width: 100%;
    z-index: 10;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 4px;
    border-radius: 5px;
    border: none;
    background-color: #D7DEE3; }
  div.progress-container progress.song-buffered-progress[value]::-webkit-progress-bar {
    background-color: #CFD8DC;
    border-radius: 5px; }
  div.progress-container progress.song-buffered-progress[value]::-webkit-progress-value {
    background-color: #F6BCBC;
    border-radius: 5px;
    transition: width .1s ease; }
  div.progress-container progress.song-buffered-progress::-moz-progress-bar {
    background: none;
    border-radius: 5px;
    background-color: #F6BCBC;
    height: 5px;
    margin-top: -2px; }
  
  div.progress-container input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    margin: 7.5px 0;
    position: absolute;
    z-index: 9999;
    top: -7px;
    height: 20px;
    cursor: pointer;
    background-color: inherit; }
  div.progress-container input[type=range]:focus {
    outline: none; }
  div.progress-container input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 0;
    cursor: pointer;
    background: #0075a9;
    border-radius: 0;
    border: 0 solid #010101; }
  div.progress-container input[type=range]::-webkit-slider-thumb {
    border: 1px solid #E40000;
    height: 15px;
    width: 15px;
    border-radius: 16px;
    background: #E40000;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7.5px; }
  div.progress-container input[type=range]:focus::-webkit-slider-runnable-track {
    background: #00adfb; }
  div.progress-container input[type=range]::-moz-range-track {
    width: 100%;
    height: 0;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(13, 13, 13, 0);
    background: #0075a9;
    border-radius: 0;
    border: 0 solid #010101; }
  div.progress-container input[type=range]::-moz-range-thumb {
    box-shadow: 0 0 0 #000000, 0 0 0 #0d0d0d;
    border: 1px solid #E40000;
    height: 15px;
    width: 15px;
    border-radius: 16px;
    background: #E40000;
    cursor: pointer; }
  div.progress-container input[type=range]::-ms-track {
    width: 100%;
    height: 0;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent; }
  div.progress-container input[type=range]::-ms-fill-lower {
    background: #003d57;
    border: 0 solid #010101;
    border-radius: 0;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(13, 13, 13, 0); }
  div.progress-container input[type=range]::-ms-fill-upper {
    background: #0075a9;
    border: 0 solid #010101;
    border-radius: 0;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(13, 13, 13, 0); }
  div,progress-container input[type=range]::-ms-thumb {
    box-shadow: 0 0 0 #000000, 0 0 0 #0d0d0d;
    border: 1px solid #E40000;
    width: 15px;
    border-radius: 16px;
    background: #E40000;
    cursor: pointer;
    height: 0;
    display: block; }

div.time-container span.current-time {
  float: left;
  width: 15%;
  text-align: center; 
}

div.time-container span.duration {
  float: left;
  width: 15%;
  text-align: center; 
}

div.time-container:after {
  content: "";
  display: table;
  clear: both; 
}

.meta-container {
  text-align: center; margin-bottom: 24px;
}

.amplitude-play-pause.amplitude-playing .play-icon {
  display: none;
}

.amplitude-play-pause:not(.amplitude-playing) .pause-icon {
  display: none;
}

.central-controls {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 12px;
}


