@import "@/styles/_index.scss"; @import "@/styles/_variables.scss"; @import "@/styles/_surveyjs_overwrite.scss";

.survey-wrapper {
  flex: 1 1 0;
  display: flex;

  :deep {
    > div {
      overflow: auto;
    }
  }
}
